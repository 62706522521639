body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-cover { 
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
}


/* custom */

.accordion-item {
  padding: 0;
}

.accordion-button { 
  border: 0;
  padding: 0.3rem 0.5rem;
}

.popover { 
  z-index: 1050;
}

.btn-outline-primary:focus { 
  background-color: #0d6efd;
  color: #fff;
}

.colorpick-eyedropper-input-trigger { 
  display: none;
}

/* card css */

.card-editing { 
  width: 23rem;
  height: 31.25rem;
  border: 1px solid #ececec;
    border-bottom: none;
    -webkit-box-shadow: 0 0 5px 0 #0000001f, 0 8px 5px -5px #0000004d;
    box-shadow: 0 0 5px 0 #0000001f, 0 8px 5px -5px #0000004d;

    position: relative;
    overflow: hidden;
}

.card-editing .box { 
  max-width: 75%;
  position: absolute;
  padding: 0.5rem 1rem;
  border: 2px dotted #ffffff00;

}

.card-editing:hover .box { 
 border-color: #636363;
}

 .card-editing .box:hover {

  cursor: move;
 }

 /* editing popup css */
 
 .editing-popup { 
  max-width: 12.5rem;
 }

 .editing-popup .color-box { 
  outline: 2px solid #e8e8e8;
   display: inline-block;
   height: 1.2rem;
   width: 1.2rem;
   border-radius: 50%;
   border: 0;
 }

 .editing-popup .color-box.active { 
  box-shadow: 0 0 0 0.2rem #007bff81;
}

.editing-popup .align-box { 
   background-color: unset;
   color: #cdd0d5;
}


.editing-popup .align-box:hover,
.editing-popup .align-box.active { 
  color: #00008B;
}

.editing-popup .align-box:focus { 
  box-shadow: unset;
}

/* === */

.img-content {
  height: 470px;
  overflow: auto;
  list-style: none;
  padding: 0;
}

.img-content li {
  color: #515a5a;
  font-size: 15px;
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 16px;
}

 .img-content li:hover {
  cursor: pointer;
  background: #f9f9fb;
  color: #000;
}